<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-title"
              label="Наименование"
              label-for="input-title"
            >
              <b-form-input
                id="input-title"
                v-model.trim="f.title"
                placeholder="Наименование"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input-group-region"
              label="Регион"
              label-for="input-region"
            >
              <b-form-select
                v-model="f.region"
                :options="['', 'Москва', 'Санкт-Петербург']"
                id="input-region"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-12 d-flex flex-wrap row-switches">
            <switch-checkbox v-model="f.is_active" label="Действ." />

            <switch-checkbox v-model="f.is_moto" label="Мото" />

            <switch-checkbox v-model="f.is_used" label="Used" />

            <switch-checkbox
              v-model="f.is_commercial_transport"
              label="Ком. транспорт "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        is_active: this.$route.query?.is_active === 'true' || false,
        is_moto: this.$route.query?.is_moto === 'true' || false,
        is_used: this.$route.query?.is_used === 'true' || false,
        is_commercial_transport:
          this.$route.query?.is_commercial_transport === 'true' || false,
        region: this.$route.query?.region,
        title: this.$route.query?.title,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 479px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 480px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
