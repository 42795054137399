<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs v-model="tab" background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab>Статусы</v-tab>
      <v-tab>Модели</v-tab>

      <v-tab-item>
        <div class="container-fluid pt-4">
          <b-form-group id="input-group-1" label="OID" label-for="input-1">
            <b-form-input
              id="input-1"
              :value="form.oid"
              placeholder="OID"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Дилер" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.title"
              placeholder="Дилер"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" label="Регион" label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="form.region"
              placeholder="Регион"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Используется"
            label-for="input-4"
          >
            <b-form-input
              id="input-4"
              :value="form.is_used | booleanYesNo"
              placeholder="Используется"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-5" label="Мото" label-for="input-5">
            <b-form-input
              id="input-5"
              :value="form.is_moto | booleanYesNo"
              placeholder="Мото"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label="Ком. транс"
            label-for="input-6"
          >
            <b-form-input
              id="input-6"
              :value="form.is_commercial_transport | booleanYesNo"
              placeholder="Ком. транс"
              disabled
            ></b-form-input>
          </b-form-group>

          <div class="form-subheader">Редактируемые</div>

          <b-form-group id="input-group-7">
            <switch-checkbox
              v-model="form.upload_settings.is_only_unregistered_vehicles"
              label="Выгружать только незарезервированные автомобили"
            />
          </b-form-group>

          <b-form-group id="input-group-7">
            <switch-checkbox
              v-model="form.upload_settings.is_show_prices"
              label="Показывать цены"
            />
          </b-form-group>

          <b-form-group id="input-group-7">
            <switch-checkbox v-model="form.upload_settings.is_show_statuses">
              <template v-slot:label>
                <div>
                  Статусы (<a href="#" @click.stop="tab = 1">Настроить</a>)
                </div>
              </template>
            </switch-checkbox>
          </b-form-group>

          <b-form-group id="input-group-7">
            <switch-checkbox v-model="form.upload_settings.is_show_models">
              <template v-slot:label>
                <div>
                  Модели (<a href="#" @click.stop="tab = 2">Настроить</a>)
                </div>
              </template>
            </switch-checkbox>
          </b-form-group>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="container-fluid">
          <v-data-table
            :headers="statusesHeaders"
            :items="this.form.statuses"
            :search="status_search"
            :custom-filter="statusFilter"
            :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
            disable-pagination
            class="elevation-1"
            item-key="status_name"
            hide-default-footer
          >
            <template v-slot:top>
              <v-text-field
                v-model="status_search"
                label="Поиск..."
                class="mx-4"
              ></v-text-field>
            </template>

            <template #item.is_upload="{ item }">
              <switch-checkbox v-model="item.is_upload" />
            </template>
          </v-data-table>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="container-fluid">
          <v-data-table
            :headers="modelsHeaders"
            :items="this.form.models"
            :search="model_search"
            :custom-filter="modelFilter"
            :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
            class="elevation-1"
            disable-pagination
            item-key="model"
            hide-default-footer
          >
            <template v-slot:top>
              <v-text-field
                v-model="model_search"
                label="Поиск..."
                class="mx-4"
              ></v-text-field>
            </template>

            <template #item.is_show_prices="{ item }">
              <switch-checkbox v-model="item.is_show_prices" />
            </template>

            <template #item.is_upload="{ item }">
              <switch-checkbox v-model="item.is_upload" />
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SwitchCheckbox from '@/view/content/SwitchCheckbox.vue';

import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';

export default {
  components: { SwitchCheckbox },
  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      tab: 0,
      statusesHeaders: [
        {
          text: 'Код',
          value: 'code',
        },

        {
          text: 'Статус',
          value: 'status_name',
        },

        {
          text: 'Выгружать',
          value: 'is_upload',
        },
      ],

      modelsHeaders: [
        {
          text: 'Марка',
          value: 'brand',
        },

        {
          text: 'Модель',
          value: 'status_name',
        },

        {
          text: 'С ценой',
          value: 'is_show_prices',
        },

        {
          text: 'Выгружать',
          value: 'is_upload',
        },
      ],
      status_search: '',
      model_search: '',
    };
  },

  computed: {
    ...mapGetters(['locksmithServices', 'bodyShops']),
    bodyShopOptions() {
      return this.bodyShops.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },

    locksmithServicesOptions() {
      return this.locksmithServices.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    statusFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        item.status_name
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    },

    modelFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        (item.brand.toString().toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
          item.model.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1)
      );
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
